import { JsonPipe } from '@angular/common';
import { Component, contentChildren, forwardRef, inject, model, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TabComponent } from './components/tab/tab.component';

@Component({
  selector: 'gth-tab-group',
  standalone: true,
  imports: [
    JsonPipe,
  ],
  templateUrl: './tab-group.component.html',
  styleUrl: './tab-group.component.scss',
})
export class TabGroupComponent implements OnInit {
  tabs = contentChildren(forwardRef(() => TabComponent), { descendants: true });
  selectedTab = model<string>();

  private activatedRoute = inject(ActivatedRoute);

  ngOnInit(): void {
    if (this.tabs().length === 0) {
      throw new Error('TabGroupComponent must contain at least one TabComponent');
    }

    const queryTab = this.activatedRoute.snapshot.queryParamMap.get('tab');

    if (this.tabs().find((tab) => tab.name() === queryTab)) {
      this.selectedTab.set(queryTab);
    }

    if (!this.selectedTab()) {
      this.selectedTab.set(this.tabs()[0].name());
    }
  }

  setActiveTab(tabName: string) {
    this.selectedTab.set(tabName);
  }
}
