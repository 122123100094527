import { Component, computed, inject, input, signal } from '@angular/core';

import { TabGroupComponent } from '../../tab-group.component';

@Component({
  selector: 'gth-tab',
  standalone: true,
  imports: [],
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss',
})
export class TabComponent {
  name = input<string>();
  label = input<string>();
  icon = input<string>();
  disabled = input<boolean>(false);
  tabGroup = inject(TabGroupComponent);
  isSelected = computed(() => this.tabGroup.selectedTab() === this.name());
}
